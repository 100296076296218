import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import bg_work from '../../images/bg_office.jpg'
import bg_education from '../../images/bg_university_kaiserslautern.jpeg'

function Resume(props) {
	const data = useStaticQuery(
    graphql`
      query {
        allFile(filter: { absolutePath: { regex: "/(resume.yaml)/" } }) {
          edges {
            node {
              childDataYaml {
                pagename
                pageintro
                sections {
                  keyName
                  name
                  entries {
                    body
                    date
                    title
                  }
                }
              }
            }
          }
        }
      }
	`)
	
	return (
		<article
			id="resume"
			className={`${props.article === 'resume' ? 'active' : ''} ${
				props.articleTimeout ? 'timeout' : ''
				}`}
			style={{ display: 'none' }}
		>
			<h1 className="major">{data.allFile.edges[0].node.childDataYaml.pagename}</h1>
			<p>{data.allFile.edges[0].node.childDataYaml.pageinfo}</p>

			{data.allFile.edges[0].node.childDataYaml.sections.map(( category ) => (
				<section id={category.keyName}>
					<span className="image main" style={{ overflow: 'hidden' }}>
						{category.keyName === 'work' ?
							<img src={bg_work} alt="" style={{ margin: '-20% 0 -15% 0' }}/>
						:
							<img src={bg_education} alt="" style={{ margin: '0 0 0 0' }}/>
						}
					</span>
					<h2>{category.name}</h2>
					{category.entries.map(( entry ) => (
						<div style={{marginTop: "2rem"}}>
							<i>{entry.date}</i>
							<h3 style={{marginBottom: "0rem"}}>{entry.title}</h3>
							<p dangerouslySetInnerHTML={{ __html: entry.body }}></p>
						</div>
					))}
				</section>
			))}

			<div className="close" onClick={() => { props.onCloseArticle() }}></div>
		</article>
	)
}
// <a target="_blank"  href="https://www.amazon.de/gp/product/3837085090/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3837085090&linkCode=as2&tag=joergrech-21&linkId=4bbca54f549e23d8b58d346c1a2a2aa0"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=DE&ASIN=3837085090&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=joergrech-21" ></a><img src="//ir-de.amazon-adsystem.com/e/ir?t=joergrech-21&l=am2&o=3&a=3837085090" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
Resume.propTypes = {
	article: PropTypes.string,
	articleTimeout: PropTypes.bool,
	onCloseArticle: PropTypes.func
}

export default Resume
