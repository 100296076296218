import React from 'react'
import PropTypes from 'prop-types'

class Contact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
				subject: '',
				message: '',
				submitMailto: "mailto:bomb@dhs.gov?subject=Help%20me!"
		};

		this.handleSubjectChange = this.handleSubjectChange.bind(this);
		this.handleMessageChange = this.handleMessageChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
  
	handleSubjectChange(event) { this.setState({subject: event.target.value}); }
	handleMessageChange(event) { this.setState({message: event.target.value}); }
  
	handleSubmit(event) {
		var mailto = "mailto:Jörg Rech<joerg.<DOMAIN>.com>?subject=".replace('<DOMAIN>','rech@gmail')+this.state.subject+"&body="+this.state.message ;
		this.setState({submitMailto: encodeURI(mailto)});
	}

	render() {
		return (
			<article
				id="contact"
				className={`${this.props.article === 'contact' ? 'active' : ''} ${
					this.props.articleTimeout ? 'timeout' : ''
				}`}
				style={{ display: 'none' }}
			>
				<h2 className="major">Contact</h2>
				<form>
					<div className="field">
						<label htmlFor="subject">Subject</label>
						<input type="text" name="subject" id="subject" onChange={this.handleSubjectChange} />
					</div>
					<div className="field">
						<label htmlFor="message">Message</label>
						<textarea name="message" id="message" rows="4" onChange={this.handleMessageChange}></textarea>
					</div>
					<ul className="actions">
						<li>
							<a id="SendEmail" className="special button" onClick={this.handleSubmit} href={this.state.submitMailto}>Send Message</a>
						</li>
						<li>
							<input type="reset" value="Reset" />
						</li>
					</ul>
				</form>

				<ul className="icons">
					<li>
						<a className="fas fa-envelope" href="mailto:joerg@joerg-rech.com" title="Email" aria-label="Email"></a>
					</li>
					{/* <li>
						<a className="fas fa-globe-europe" href="https://www.gulp.de/Profil/joerg.rech.html" title="Gulp.de"></a>
					</li>	
					<li>
						<a className="fas fa-globe-americas" href="https://www.freelancermap.de/profil/J&ouml;rgRech/1" title="FreelancerMap.de"></a>
					</li>	 */}
					{/* <li>
						<a className="fas fa-globe-africa" href="https://www.freelance.de/Freiberufler/44668" title="Freelance.de"></a>
					</li>	 */}
					<li>
						<a className="fab fa-twitter" href="https://twitter.com/joergrech" title="Twitter"></a>
					</li>
					<li>
						<a className="fab fa-linkedin" href="https://www.linkedin.com/in/joergrech" title="LinkedIn"></a>
					</li>
					<li>
						<a className="fab fa-xing" href="https://www.xing.com/hp/Joerg_Rech/" title="Xing"></a>
					</li>
					<li>
						<a className="fab fa-github" href="https://www.github.com/joergrech" title="GitHub"></a>
					</li>
					<li>
						<a className="fab fa-stack-overflow" href="https://www.stackoverflow.com/users/438001/jorg-rech" title="Stack Overflow"></a>
					</li>
					<li>
						<a className="fab fa-dev" href="https://dev.to/joergrech" title="DEV Community"></a>
					</li>
					<li>
						<a className="fab fa-product-hunt" href="https://www.producthunt.com/@jorg_rech" title="Product Hunt"></a>
					</li>
					<li>
						<a className="fab fa-angellist" href="https://angel.co/u/joerg-rech" title="AngelList"></a>
					</li>	
					{/* <li> WARN: NO Fontawesome Icon for crunchbase !!!
						<a className="fab fa-crunchbase" href="https://www.crunchbase.com/person/joerg-rech" title="CrunchBase"></a>
					</li>	 */}
					<li>
						<a className="fab fa-researchgate" href="https://www.researchgate.net/profile/Joerg_Rech" title="ResearchGate"></a>
					</li>
					<li>
						<a className="fab fa-mendeley" href="https://www.mendeley.com/profiles/jorg-rech" title="Mendeley"></a>
					</li>	
				</ul>

				<div className="close" onClick={() => {this.props.onCloseArticle()}}></div>
			</article>
		)
	}
}

Contact.propTypes = {
	article: PropTypes.string,
	articleTimeout: PropTypes.bool,
	onCloseArticle: PropTypes.func
}

export default Contact
