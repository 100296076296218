import React from 'react'
import PropTypes from 'prop-types'

import bg_laptop from '../../images/bg_laptop.jpg'

import { useStaticQuery, graphql } from 'gatsby'

function About(props) {
	let externalLink = (url, title) => (
	<a target="_blank" rel="noopener noreferrer" href={url}>
		{title}
	</a>
	)
	const data = useStaticQuery(
	graphql`
	query {
		allFile(filter: { absolutePath: { regex: "/(about.yaml)/" } }) {
			edges {
				node {
					childDataYaml {
						pagename
						pageintro
						sections {
							name
							body
						}
					}
				}
			}
		}
	}
`
	)

	return (
    <article
      id="about"
      className={`${props.article === 'about' ? 'active' : ''} ${
        props.articleTimeout ? 'timeout' : ''
      }`}
      style={{ display: 'none' }}
    >
      <h1 className="major">{data.allFile.edges[0].node.childDataYaml.pagename}</h1>
			<p>
				<div dangerouslySetInnerHTML={{ __html: data.allFile.edges[0].node.childDataYaml.pageintro }}></div>
			</p>
      <span className="image main" style={{ overflow: 'hidden' }}>
        <img src={bg_laptop} alt="" style={{ margin: '-6% 0' }} />
      </span>

      <h2 className="major">Offerings</h2>

      {data.allFile.edges[0].node.childDataYaml.sections.map(( node ) => (
				<>
		      <h3 className="minor" style={{marginTop: "2rem"}}>{node.name}</h3>
					<div dangerouslySetInnerHTML={{ __html: node.body }}></div>
				</>
      ))}

      <div
        className="close"
        onClick={() => {
          props.onCloseArticle()
        }}
      ></div>
    </article>
  )
}

About.propTypes = {
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
}

export default About
