import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import bg_active from '../../images/seeds_2.jpg'
import bg_exited from '../../images/tree_single.jpg'
import bg_closed from '../../images/tree_dead_2.jpg'
import bg_dormant from '../../images/trees_snow_2.jpg'

function Projects(props) {
	const data = useStaticQuery(
    graphql`
      query {
        allFile(filter: { absolutePath: { regex: "/(projects.yaml)/" } }) {
          edges {
            node {
              childDataYaml {
                pagename
                pageintro
                sections {
                  keyName
                  name
                  projects {
                    name
                    link
                    body
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return (
		<article
			id="projects"
			className={`${props.article === 'projects' ? 'active' : ''} ${
				props.articleTimeout ? 'timeout' : ''
				}`}
			style={{ display: 'none' }}
		>
			<h1 className="major">{data.allFile.edges[0].node.childDataYaml.pagename}</h1>
			<p>
				{data.allFile.edges[0].node.childDataYaml.pageintro}
			</p>

			{data.allFile.edges[0].node.childDataYaml.sections.map(( category ) => (
				<section id={category.keyName}>
					<span className="image main" style={{ overflow: 'hidden' }}>
						{category.keyName === 'active' ?
							<img src={bg_active}	alt="" style={{ margin: '-10% 0 -15% 0' }} />
						: category.keyName === 'exited' ?
							<img src={bg_exited}	alt="" style={{ margin: '-20% 0 -0% 0' }} />
						: category.keyName === 'dormant' ?
							<img src={bg_dormant}	alt="" style={{ margin: '-70% 0 -30% 0' }} />
						:
							<img src={bg_closed}	alt="" style={{ margin: '-10% 0 -10% 0' }} />
						}
					</span>
					<h2>{category.name}</h2>
					{category.projects.map(( project ) => (
						<a href={project.link} target="_blank" rel="noopener noreferrer">
							<h3>{project.name}</h3>
							<p dangerouslySetInnerHTML={{ __html: project.body }}></p>
						</a>
					))}
				</section>
			))}

			<div
				className="close"
				onClick={() => {
					props.onCloseArticle()
				}}
			></div>
		</article>
	)
}

Projects.propTypes = {
	article: PropTypes.string,
	articleTimeout: PropTypes.bool,
	onCloseArticle: PropTypes.func,
}

export default Projects
