import PropTypes from 'prop-types'
import React from 'react'


import About	from './subpages/About'
import Projects	from './subpages/Projects'
import Resume	from './subpages/Resume'
import Contact	from './subpages/Contact'
import Imprint	from './subpages/Imprint'

class Main extends React.Component {

	render() {
		return (
			<div
				ref={this.props.setWrapperRef}
				id="main"
				style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
			>
				<About 
					article={this.props.article}
					articleTimeout={this.props.articleTimeout}
					onCloseArticle={this.props.onCloseArticle}
				/>

				<Projects 
					article={this.props.article}
					articleTimeout={this.props.articleTimeout}
					onCloseArticle={this.props.onCloseArticle}
				/>

				<Resume 
					article={this.props.article}
					articleTimeout={this.props.articleTimeout}
					onCloseArticle={this.props.onCloseArticle}
				/>

				<Contact 
					article={this.props.article}
					articleTimeout={this.props.articleTimeout}
					onCloseArticle={this.props.onCloseArticle}
				/>

				<Imprint 
					article={this.props.article}
					articleTimeout={this.props.articleTimeout}
					onCloseArticle={this.props.onCloseArticle}
				/>
			</div>
		)
	}
}

Main.propTypes = {
	route: PropTypes.object,
	article: PropTypes.string,
	articleTimeout: PropTypes.bool,
	onCloseArticle: PropTypes.func,
	timeout: PropTypes.bool,
	setWrapperRef: PropTypes.func.isRequired
}

export default Main
