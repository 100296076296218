import React from 'react'
import PropTypes from 'prop-types'

import bg_work from '../../images/bg_office.jpg'

const xml_vocabulary = {
	"xmlns:v": "https://rdf.data-vocabulary.org/#"
};

const Imprint = (props) => (
	<article
		id="imprint"
		className={`${props.article === 'imprint' ? 'active' : ''} ${
			props.articleTimeout ? 'timeout' : ''
		}`}
		style={{ display: 'none' }}
	>
		<h2 className="major">Imprint</h2>
		<span className="image main" style={{ overflow: 'hidden' }}>
			<img src={bg_work} alt="" style={{ margin: '-20% 0 -15% 0' }}/>
		</span>
		<div>
			<h2>Operator and Contact</h2>
			<address itemScope itemType="https://data-vocabulary.org/Person" {...xml_vocabulary} typeof="v:Person">
				<div itemProp="name" property="v:name">
					<b>
						<span style={{ marginRight: "0.5em" }}	itemProp="honorific-prefix">Dr.</span>
						<span style={{ marginRight: "0.5em" }}	itemProp="given-name">J&ouml;rg</span>
						<span style={{ marginRight: "0.5em" }}	itemProp="family-name">Rech</span>
					</b>
				</div>
											
				<div itemProp="address" itemScope itemType="https://schema.org/PostalAddress" rel="v:address">
					<span typeof="v:Address">
						<div itemProp="streetAddress" property="v:street-address">Welfenstr. 32</div>
						<div >
							<span style={{ marginRight: "0.5em" }}	itemProp="postalCode" property="v:postal-code">76137</span>
							<span style={{ marginRight: "0.5em" }}	itemProp="locality" property="v:locality">Karlsruhe</span>
						</div>
						<div itemProp="countryName" property="v:country-name">Germany</div>
						<br />
						<div itemProp="telephone" property="v:telephone">Phone: 0163 7324 377</div>
						<div itemProp="email" property="v:email">E-Mail: joerg@joerg-rech.com</div>
					</span>
				</div>
				<span	itemProp="gender"	property="v:gender"		style={{ display: 'none' }}>Male</span>
				<span	itemProp="jobTitle"	property="v:title"		style={{ display: 'none' }}>Software Engineer</span>
				<span	itemProp="role"		property="v:role"		style={{ display: 'none' }}>Entrepreneur</span>
				<span	itemProp="affiliation" property="v:affiliation" style={{ display: 'none' }}>Semantic Technologies</span>

				<div style={{ display: 'none' }}>
					<span itemProp="category"	property="v:category"	style={{ display: 'none' }}>Groovy, Grails, Android, iPhone, iPad, iOS, PhoneGap, Javascript, jQuery, Software Developer, Software Engineer, Web Engineer, Software Engineering, IT Consultant, Research</span>
					<span itemProp="note"		property="v:note"		style={{ display: 'none' }}>Freelancer and Software Engineer from Karlsruhe (Germany) working in the IT sector and interested in the startup scene, building lean startups, developing new interesting software, and conducting empircal research.</span>
				</div>
			</address>
			<br />

			<h3>Legal Information</h3>
			<p>
				Sales tax identification number (Umsatzsteuer-Identifikationsnummer): DE326118716<br />
				Court of Jurisdiction (Gerichtsstand): Karlsruhe, Germany
			</p>

			<h2>Disclaimer (English)</h2>
			<p>
				This Web site may contain other proprietary notices and copyright information, the terms of which must be observed and followed. Information on this Web site may contain technical inaccuracies or typographical errors. Information may be changed or updated without notice. 
			</p>
			<p>
				The operator and author may also make improvements and/or changes in the products and/or the programs described in this information at any time without notice. For documents and software available from this server, the operator and author does not warrant or assume any legal liability or responsibility for the accuracy, completeness, or usefulness of any information, apparatus, product, or process disclosed.
			</p>

			<h2>Disclaimer (German)</h2>

			<h3>§ 1 Haftungsbeschr&auml;nkung</h3>
			<p>
				Die Inhalte dieser Website werden mit gr&ouml;&szlig;tm&ouml;glicher Sorgfalt erstellt. Der Anbieter &uuml;bernimmt jedoch keine Gew&auml;hr f&uuml;r die Richtigkeit, Vollst&auml;ndigkeit und Aktualit&auml;t der bereitgestellten Inhalte. Die Nutzung der Inhalte der Website erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beitr&auml;ge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Mit der reinen Nutzung der Website des Anbieters kommt keinerlei Vertragsverh&auml;ltnis zwischen dem Nutzer und dem Anbieter zustande.
			</p>

			<h3>§ 2 Externe Links</h3>
			<p>
				Diese Website enth&auml;lt Verkn&uuml;pfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verkn&uuml;pfung der externen Links die fremden Inhalte daraufhin &uuml;berpr&uuml;ft, ob etwaige Rechtsverst&ouml;&szlig;e bestehen. Zu dem Zeitpunkt waren keine Rechtsverst&ouml;&szlig;e ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zuk&uuml;nftige Gestaltung und auf die Inhalte der verkn&uuml;pften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine st&auml;ndige Kontrolle der externen Links ist f&uuml;r den Anbieter ohne konkrete Hinweise auf Rechtsverst&ouml;&szlig;e nicht zumutbar. Bei Kenntnis von Rechtsverst&ouml;&szlig;en werden jedoch derartige externe Links unverz&uuml;glich gel&ouml;scht.
			</p>

			<h3>§ 3 Urheber- und Leistungsschutzrechte</h3>
			<p>
				Die auf dieser Website ver&ouml;ffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere f&uuml;r Vervielf&auml;ltigung, Bearbeitung, &uuml;bersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielf&auml;ltigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads f&uuml;r den pers&ouml;nlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.
			</p>

			<p>
				Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zul&auml;ssig.
			</p>

			<h3>§ 4 Besondere Nutzungsbedingungen</h3>
			<p>
				Soweit besondere Bedingungen f&uuml;r einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle ausdr&uuml;cklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.
			</p>
		</div>
		<div className="close" onClick={() => {props.onCloseArticle()}}></div>
	</article>
)

Imprint.propTypes = {
	article: PropTypes.string,
	articleTimeout: PropTypes.bool,
	onCloseArticle: PropTypes.func
}

export default Imprint
