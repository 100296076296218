import React from 'react'
import PropTypes from 'prop-types'

import headshot_image from '../images/Joerg_weiss_4x3_240px.png'

const avatar_style = {
    width: "300px",
    height: "300px",
    borderRadius: "50%",
    position: "relative",
    cursor: "default",
    boxShadow: "inset 0 0 0 0 rgba(200,95,66, 0.4), inset 0 0 0 16px rgba(255,255,255,0.6), 0 1px 2px rgba(0,0,0,0.1)",
	margin: "0 auto",
	background: "#fff url("+ headshot_image +") 30px 10px no-repeat"
};

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo" style={avatar_style}>
        </div>
        <div className="content">
            <div className="inner">
				<h1>Dr. Jörg Rech</h1>
				<h3>Developer / Entrepreneur / Researcher</h3>
				<p>
					I'm an Entrepreneur and Full-Stack Developer from Germany interested in the automation of previously manual processes and building sustainable startups. 
					Currently, I'm acting as the CEO of <a target="_blank" rel="noopener noreferrer" href="https://techmap.io">Techmap.io</a>, working on ideas to better monetize Open-Source Software via <a target="_blank" rel="noopener noreferrer" href="https://paydevs.com">PayDevs.com</a>, and support other entreprenuers as Startup Advisor. 
                    Previously, I co-founded <a target="_blank" rel="noopener noreferrer" href="https://talentwunder.com">Talentwunder</a> and worked almost 10 years in applied research at&nbsp;
					<a target="_blank" rel="noopener noreferrer" href="https://www.sap.com">SAP Research</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.iese.fraunhofer.de">Fraunhofer IESE</a>.
				</p>
			</div>
        </div>
        <nav>
            <ul>
				<li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}	>About</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('projects')}}>Projects</a></li>
				<li><a href="javascript:;" onClick={() => {props.onOpenArticle('resume')}}	>Resumé</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}	>Contact</a></li>
            </ul>
        </nav>
    </header>
)
/* Alternative pages: 
Investor (Investments, etc.)
Advisor
Entrepreneur (Startups, Projects, etc.)
Freelancer (Resume, Skills etc.),
Researcher (Publications)
*/
// <li><a href="javascript:;" onClick={() => {props.onOpenArticle('paper')}}	>Publications</a></li>
// <span className="icon fa-diamond"></span>

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
