import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; Dr. Jörg Rech. Built with: <a href="https://reactjs.org/">React.js</a> & <a href="https://www.gatsbyjs.org/">Gatsby.js</a></p>
        <p className="copyright"><a href="javascript:;" onClick={() => {props.onOpenArticle('imprint')}}>Imprint</a></p>
    </footer>
)
// <li><a href="javascript:;" onClick={() => {props.onOpenArticle('imprint')}}>Imprint</a></li>
// Based on the DIMENSION Design by: <a href="https://html5up.net">HTML5 UP</a>. 
Footer.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Footer
